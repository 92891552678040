import * as React from 'react';
import './sixTileInfoBox.scss';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

export interface IInfoBoxProps {
  title: string;
  subtitle: string;
  imgUrl?: string;
}
const InfoBox: React.FC<IInfoBoxProps> = props => {
  return (
    <div
      className='has-text-centered info-box'
      style={{
        paddingLeft: '1rem',
        paddingRight: '1rem'
      }}
    >
      <img src={props.imgUrl} style={{ marginBottom: '1rem' }} />
      <span
        style={{
          fontSize: '1.625rem',
          lineHeight: '1.875rem',
          color: 'white',
          fontWeight: 700,
          display: 'block',
          marginBottom: '1rem'
        }}
      >
        {props.title}
      </span>
      <span
        style={{
          color: '#A0BBC8',
          lineHeight: '1.5rem',
          fontWeight: 500,
          fontSize: '1rem'
        }}
      >
        {props.subtitle}
      </span>
    </div>
  );
};

const query = graphql`
  query SixTileInfo {
    desktop: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
interface ISixTileInfoBoxProps {
  title: string;
  subtitle: string;
  infoBoxes: IInfoBoxProps[];
  isLandingPage?: boolean;
}

export const SixTileInfoBox: React.FC<ISixTileInfoBoxProps> = props => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImage
            fluid={imageData}
            style={{ backgroundImage: '' }}
            backgroundColor='#03032b'
            className='six-tile-info main-padding'
            critical={true}
          >
            <div className='columns'>
              <div className='column is-6'>
                <h2 id='title'>{props!.title}</h2>
                <span id='subtitle'>{props!.subtitle}</span>
              </div>
            </div>
            <div className='columns'>
              <div className='column is-4 info-column'>
                <InfoBox
                  imgUrl={
                    props.infoBoxes[0].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[0].title}
                  subtitle={props.infoBoxes[0].subtitle}
                />
                <InfoBox
                  imgUrl={
                    props.infoBoxes[1].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[1].title}
                  subtitle={props.infoBoxes[1].subtitle}
                />
              </div>
              <div className='column is-4 info-column'>
                <InfoBox
                  imgUrl={
                    props.infoBoxes[2].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[2].title}
                  subtitle={props.infoBoxes[2].subtitle}
                />
                <InfoBox
                  imgUrl={
                    props.infoBoxes[3].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[3].title}
                  subtitle={props.infoBoxes[3].subtitle}
                />
              </div>
              <div className='column is-4 info-column'>
                <InfoBox
                  imgUrl={
                    props.infoBoxes[4].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[4].title}
                  subtitle={props.infoBoxes[4].subtitle}
                />
                <InfoBox
                  imgUrl={
                    props.infoBoxes[5].imgUrl ||
                    'https://via.placeholder.com/46'
                  }
                  title={props.infoBoxes[5].title}
                  subtitle={props.infoBoxes[5].subtitle}
                />
              </div>
            </div>
          </BackgroundImage>
        );
      }}
    />
  );
};
