import React from 'react';
import './howToBox.scss';
import '../../layout/layout.scss';

export const HowToBox: React.FC<{
  text: string;
  imgUrl: string;
  number: string;
}> = props => {
  return (
    <div className='how-to-box'>
      <div id='rectangle'>
        <span>{props.number}</span>
      </div>
      <img src={props.imgUrl} />
      <span>{props.text}</span>
    </div>
  );
};
